<template>
  <div class="home" ref="homeContent">
    <!-- 内容开始 -->
    <div class="content">
      <div class="banner-content">
        <!-- banner 切换开始 -->
        <el-carousel
          trigger="click"
          class="hidden-xs-only"
          :height="height"
          indicator-position="none"
          :interval="4500"
        >
          <el-carousel-item v-for="(item, index) in bannerLists" :key="index">
            <div ref="bannerImg" class="banner-img">
              <img :src="item.path" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          trigger="click"
          class="hidden-sm-and-up"
          height="400px"
          indicator-position="none"
          :interval="4500"
        >
          <el-carousel-item v-for="(item, index) in bannerLists" :key="index">
            <div class="banner-img-sm"><img :src="item.path" alt="" /></div>
          </el-carousel-item>
        </el-carousel>
        <!-- banner 切换结束 -->
        <!-- banner 内容开始 -->
        <div class="middle">
          <!-- 内容容器开始 -->
          <div class="container">
            <!-- 顶部导航开始 -->
            <my-header
              class="hidden-xs-only"
              active="0"
              :phoneShow="1"
            ></my-header>
            <language-change2
              class="hidden-sm-and-up"
              active="0"
            ></language-change2>
            <!-- 顶部导航结束 -->
            <!-- 文本1开始 -->
            <div class="middle-content1">
              <span> BUILDING </span>
              high-quality service experience venue in China
            </div>
            <!-- 文本1结束 -->
            <!-- 文本2开始 -->
            <div class="middle-content2">打造中国优质服务体验场馆</div>
            <!-- 文本2结束 -->
            <!-- 文本3开始 -->
            <div class="middle-group">
              <div class="middle-btn white-btn" @click="herfVR">
                {{ $t("message.panoramic") }}
              </div>
              <div
                class="middle-btn hidden-xs-only"
                @click="dialogMovie = true"
              >
                {{ $t("message.promoVideo") }}
              </div>
              <div
                class="middle-btn hidden-sm-and-up"
                @click="dialogMovieSm = true"
              >
                {{ $t("message.promoVideo") }}
              </div>
            </div>
            <!-- 文本3结束 -->
          </div>
          <!-- 内容容器结束 -->
        </div>
        <!-- banner 内容结束 -->
        <!-- 可下滑提示开始 -->
        <div class="prompt hidden-xs-only" v-show="promptShow">
          <div class="prompt-btn" @click="handleDown">
            <img src="../assets/svg/down-arrow-icon.svg" alt="" />
          </div>
          <div class="prompt-spot">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <!-- 可下滑提示结束 -->
        <!-- 宣传片弹窗开始 -->
        <el-dialog
          title="武汉国博宣传片"
          width="50%"
          :visible.sync="dialogMovie"
          :close-on-click-modal="false"
          :destroy-on-close="true"
        >
          <div class="video-content">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </el-dialog>
        <el-dialog
          title="武汉国博宣传片"
          width="100%"
          :visible.sync="dialogMovieSm"
          :close-on-click-modal="false"
        >
          <div class="video-content">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
            >
            </video-player>
          </div>
        </el-dialog>
        <!-- 宣传片弹窗结束 -->
      </div>
      <!-- 底部切换开始 -->
      <el-carousel
        class="hidden-xs-only"
        trigger="click"
        height="150px"
        indicator-position="none"
        :interval="4500"
      >
        <el-carousel-item v-for="(item, index) in scheduleLists" :key="index">
          <!-- 底部提示开始 -->
          <div class="content">
            <div class="bottom" @click="handleScheduleDetail(item.id)">
              <!-- 内容容器开始 -->
              <div class="container">
                <div class="bottom-content">
                  <div class="bottom-content-msg1">
                    {{ $t("message.notice") }} >>
                  </div>
                  <div class="bottom-content-box">
                    <div class="bottom-content-msg2">
                      <div v-show="language == 'cn'">{{ item.name }}</div>
                      <div v-show="language == 'en'">{{ item.enName }}</div>
                    </div>
                    <div class="flex">
                      <div class="bottom-content-msg3">
                        <span class="label">
                          {{ $t("message.scheduleTime") }}：</span
                        >
                        <span class="msg">
                          {{ item.startTime | filterTime }}
                          -
                          {{ item.endTime | filterTime }}
                        </span>
                      </div>
                      <div class="bottom-content-msg3">
                        <span class="label">
                          {{ $t("message.scheduleAddress") }}：</span
                        >
                        <span class="msg">
                          <span v-show="language == 'cn'">{{ item.expo }}</span>
                          <span v-show="language == 'en'">
                            {{ item.enExpo }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 内容容器结束  -->
            </div>
          </div>
          <!-- 底部提示结束 -->
        </el-carousel-item>
      </el-carousel>
      <!-- 底部切换结束 -->
      <!-- 展会预告开始 -->
      <div class="schedule hidden-sm-and-up">
        <div class="container">
          <!-- 标题开始 -->
          <div class="schedule-title">
            <div class="schedule-title-left">{{ $t("message.notice") }}</div>
            <div class="schedule-title-right" @click="handleSchedule">>></div>
          </div>
          <!-- 标题结束 -->
          <!-- 单一内容开始 -->
          <div
            class="schedule-item"
            :class="index == 0 ? 'schedule-item-bg' : ''"
            v-for="(item, index) in scheduleLists"
            :key="index"
            @click="handleScheduleDetail(item.id)"
          >
            <!-- 标题开始 -->
            <div class="schedule-item-title">
              <div v-show="language == 'cn'">{{ item.name }}</div>
              <div v-show="language == 'en'">{{ item.enName }}</div>
            </div>
            <!-- 标题结束 -->
            <!-- 内容开始 -->
            <div class="schedule-item-content">
              <div>
                {{ $t("message.scheduleTime") }}：
                {{ item.startTime | filterTime }}
                -
                {{ item.endTime | filterTime }}
              </div>
              <div>
                {{ $t("message.scheduleAddress") }}：
                <span v-show="language == 'cn'">{{ item.expo }}</span>
                <span v-show="language == 'en'">
                  {{ item.enExpo }}
                </span>
              </div>
            </div>
            <!-- 内容结束 -->
          </div>
          <!-- 单一内容结束 -->
        </div>
      </div>
      <!-- 展会预告结束 -->
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      height: "",
      bannerLists: [], //背景轮播数据
      scheduleLists: [], //预告轮播数据
      promptShow: true, //下面还有内容提示
      dialogMovie: false,
      dialogMovieSm: false,
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: require("../assets/video/trailer.mp4"),
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
    let windowHeight = window.innerHeight;
    if (windowHeight > 600) {
      this.height = windowHeight - 50 + "px";
    } else {
      this.height = "640px";
    }
    // 背景轮播图
    this.$axios
      .get("api/imgList?type=4&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.bannerLists = res.data.data;
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    // 预告轮播
    const date = new Date();
    let year = date.getFullYear(); //当前年份
    let month = date.getMonth() + 1; //当前月份
    this.$axios
      .get(`api/expoList?state=1&year=${year}&month=${month}&type=index`, {})
      .then((res) => {
        if (res.data.code == 0) {
          this.scheduleLists = res.data.data;
        }
      });
    this.$nextTick(() => {
      setInterval(() => {
        this.$refs.bannerImg.forEach((item) => {
          item.style.height = this.height;
        });
      }, 1000);
    });
  },
  methods: {
    // 打开VR
    herfVR() {
      window.open(" http://www.juyatu.com/test/guobo/");
    },
    // 查看展会
    handleSchedule() {
      this.$router.push({ path: "/schedule" });
    },
    // 查看展会详情
    handleScheduleDetail(value) {
      this.$router.push({ path: "/scheduleDetail", query: { id: value } });
    },
    // 容器滑动事件
    handleScrollx() {
      let height = this.$refs.homeContent.getBoundingClientRect().top;
      if (height < 40) {
        this.promptShow = false;
      } else {
        this.promptShow = true;
      }
    },
    // 下滑提示点击事件
    handleDown() {
      window.scrollTo({
        top: 320,
        behavior: "smooth",
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
  filters: {
    filterTime: function (value) {
      let date = new Date(value);
      let month = date.getMonth() + 1; //当前月份
      let day = date.getDate();
      return `${month}/${day}`;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.banner-content {
  position: relative;
}

.banner-img img {
  height: 100%;
}

.banner-img-sm img {
  height: 400px;
}

.middle {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}

.middle-content1 {
  color: #f8f7f7;
  font-size: 22px;
  font-family: Arial;
  font-weight: bold;
  line-height: 30px;
  margin-top: 100px;
}

.middle-content1 span {
  font-size: 22px;
}

.middle-content2 {
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  margin-top: 30px;
}

.middle-group {
  margin-top: 40px;
  display: flex;
}

.middle-btn {
  width: 120px;
  height: 36px;
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background: #1691fa;
  text-align: center;
  line-height: 36px;
  margin-right: 16px;
  cursor: pointer;
}

.white-btn {
  color: #1691fa;
  background: #ffffff;
}

.banner-content >>> .el-dialog__body {
  padding: 0px 20px 20px;
}

@media (min-width: 768px) {
  .middle {
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .middle-content1 {
    color: #f8f7f7;
    font-size: 60px;
    font-family: Arial;
    font-weight: bold;
    line-height: 80px;
    margin-top: 50px;
  }

  .middle-content1 span {
    font-size: 68px;
  }

  .middle-content2 {
    color: #ffffff;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 33px;
    margin-top: 30px;
  }

  .middle-group {
    margin-top: 60px;
    display: flex;
  }

  .middle-btn {
    width: 204px;
    height: 58px;
    color: #ffffff;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background: #1691fa;
    text-align: center;
    line-height: 58px;
    margin-right: 24px;
    cursor: pointer;
  }

  .white-btn {
    color: #1691fa;
    background: #ffffff;
  }

  .banner-content >>> .el-dialog__body {
    padding: 0px 20px 20px;
  }
}

.prompt {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 21;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.prompt-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  text-align: center;
  cursor: pointer;
  animation: move 1s infinite linear;
}

@keyframes move {
  0% {
    line-height: 40px;
  }
  25% {
    line-height: 50px;
  }
  50% {
    line-height: 40px;
  }
  75% {
    line-height: 30px;
  }
  100% {
    line-height: 40px;
  }
}

.prompt-spot {
  width: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 10px 0 8px;
}

.prompt-spot span {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 2px 0;
}

.bottom {
  height: 150px;
  background: #004bc7;
  cursor: pointer;
}

.bottom-content {
  display: flex;
}

.bottom-content-msg1 {
  height: 150px;
  width: 350px;
  color: #ffffff;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 150px;
}

.bottom-content-box {
  height: 150px;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.bottom-content-msg2 {
  color: #ffffff;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.bottom-content-msg3 {
  color: #ffffff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
}

.bottom-content-msg3 + .bottom-content-msg3 {
  margin-left: 30px;
}

.schedule {
  margin: 20px 0;
}

.schedule-title {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule-title-left {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  border-bottom: 1px solid #0152d9;
  line-height: 33px;
  padding: 8px 0;
  margin-bottom: -1px;
}

.schedule-title-right {
  color: #004bc7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 33px;
}

.schedule-item {
  background-color: #f5f5f5;
  margin-top: 12px;
  padding: 20px;
}

.schedule-item-bg {
  background-color: #004bc7;
}

.schedule-item-title {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 10px;
}

.schedule-item-content {
  color: #666666;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 33px;
}

.schedule-item-bg .schedule-item-title,
.schedule-item-bg .schedule-item-content {
  color: #ffffff;
}
</style>
