<template>
  <div class="content">
    <!-- 内容容器开始 -->
    <div class="">
      <div class="top-content">
        <!-- 左侧内容开始 -->
        <div class="top-content-left">
          <img src="../assets/img/logo.png" alt="" />
        </div>
        <!-- 左侧内容结束 -->
        <!-- 右侧内容开始 -->
        <div class="top-content-right">
          <!-- 下拉选择语言开始 -->
          <el-dropdown trigger="click" @command="handleLanguage">
            <span class="el-dropdown-link language">
              <span>{{ languageMsg }}</span>
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="cn">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 下拉选择语言结束 -->
          <!-- 小程序click开始 -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link applet">
              <img src="../assets/img/Applet-icon1.png" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="code-img">
                <img src="../assets/img/Applet-code.jpg" alt="" />
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 小程序结束 -->
          <!-- 抖音开始 -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link Tiktok">
              <img src="../assets/img/Tiktok-icon1.png" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="code-img">
                <img src="../assets/img/Tiktok-code.jpg" alt="" />
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 抖音结束 -->
          <!-- 导航开始 -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link navigation">
              <img src="../assets/img/navigation-icon1.png" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <div class="nav-box">
                <div class="nav-item">
                  <router-link
                    class="nav-item-a"
                    :class="navActive == 0 ? 'nav-active' : ''"
                    to="/"
                    >{{ $t("message.topNav1") }}</router-link
                  >
                </div>
                <el-collapse accordion>
                  <el-collapse-item>
                    <template slot="title">
                      <div
                        class="nav-item-a"
                        :class="navActive == 1 ? 'nav-active' : ''"
                        @click="handleSubNav('/about?tab=0')"
                      >
                        {{ $t("message.topNav2") }}
                      </div>
                    </template>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/about?tab=0')"
                      >
                        {{ $t("message.subNav2_1") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/about?tab=1')"
                      >
                        {{ $t("message.subNav2_2") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/about?tab=2')"
                      >
                        {{ $t("message.subNav2_3") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/about?tab=3')"
                      >
                        {{ $t("message.subNav2_4") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/about?tab=4')"
                      >
                        {{ $t("message.subNav2_5") }}
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item>
                    <template slot="title">
                      <div
                        class="nav-item-a"
                        :class="navActive == 2 ? 'nav-active' : ''"
                        @click="handleSubNav('/service?tab=1')"
                      >
                        {{ $t("message.topNav3") }}
                      </div>
                    </template>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/service?tab=1')"
                      >
                        {{ $t("message.subNav3_1") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/service?tab=2')"
                      >
                        {{ $t("message.subNav3_2") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/service?tab=3')"
                      >
                        {{ $t("message.subNav3_3") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/service?tab=4')"
                      >
                        {{ $t("message.subNav3_4") }}
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div class="nav-item">
                  <router-link
                    class="nav-item-a"
                    :class="navActive == 3 ? 'nav-active' : ''"
                    to="/schedule"
                    >{{ $t("message.topNav4") }}</router-link
                  >
                </div>
                <div class="nav-item">
                  <router-link
                    class="nav-item-a"
                    :class="navActive == 4 ? 'nav-active' : ''"
                    to="/news"
                    >{{ $t("message.topNav5") }}</router-link
                  >
                </div>
                <el-collapse accordion>
                  <el-collapse-item>
                    <template slot="title">
                      <div
                        class="nav-item-a"
                        :class="navActive == 5 ? 'nav-active' : ''"
                        @click="handleSubNav('/traffic?tab=0')"
                      >
                        {{ $t("message.topNav6") }}
                      </div>
                    </template>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/traffic?tab=0')"
                      >
                        {{ $t("message.subNav6_1") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/traffic?tab=1')"
                      >
                        {{ $t("message.subNav6_2") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/traffic?tab=2')"
                      >
                        {{ $t("message.subNav6_3") }}
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item>
                    <template slot="title">
                      <div
                        class="nav-item-a"
                        :class="navActive == 6 ? 'nav-active' : ''"
                        @click="handleSubNav('/Local?tab=0')"
                      >
                        {{ $t("message.topNav7") }}
                      </div>
                    </template>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/Local?tab=0')"
                      >
                        {{ $t("message.subNav7_1") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/Local?tab=1')"
                      >
                        {{ $t("message.subNav7_2") }}
                      </div>
                    </div>
                    <div>
                      <div
                        class="nav-subItem"
                        @click="handleSubNav('/Local?tab=2')"
                      >
                        {{ $t("message.subNav7_3") }}
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div class="nav-item">
                  <router-link
                    class="nav-item-a"
                    :class="navActive == 7 ? 'nav-active' : ''"
                    to="/contentUs"
                    >{{ $t("message.topNav8") }}</router-link
                  >
                </div>
                <div class="nav-item">
                  <router-link
                    class="nav-item-a"
                    :class="navActive == 9 ? 'nav-active' : ''"
                    to="/download"
                    >{{ $t("message.topNav8") }}</router-link
                  >
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 导航结束 -->
        </div>
        <!-- 右侧内容结束 -->
      </div>
    </div>
    <!-- 内容容器结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      languageMsg: "中文",
      navActive: "",
    };
  },
  props: {
    active: String,
  },
  mounted() {
    if (this.language == "cn") {
      this.languageMsg = "中文";
    } else if (this.language == "en") {
      this.languageMsg = "English";
    }
    this.getNavActive();
  },
  methods: {
    refresh() {
      this.reload();
    },
    // 选择子导航
    handleSubNav(value) {
      this.$router.push({ path: value });
      this.refresh();
    },
    // 获取导航默认选中
    getNavActive() {
      this.navActive = this.active;
    },
    // 选择语言
    handleLanguage(command) {
      if (command == "cn") {
        this.languageMsg = "中文";
      } else if (command == "en") {
        this.languageMsg = "English";
      }
      this.$store.commit("changeLanguage", command);
      localStorage.setItem("language", command);
      this.$i18n.locale = command;
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-content-left {
  width: 130px;
  height: 45px;
}

.top-content-left img {
  width: 100%;
}

.top-content-right {
  display: flex;
  align-items: center;
}

.language {
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.language span {
  margin-right: 16px;
}

.applet {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-left: 12px;
  display: inline-block;
}

.code-img {
  width: 160px;
  padding: 10px;
  cursor: pointer;
}

.el-dropdown-link img,
.navigation img,
.code-img img {
  width: 100%;
}

.Tiktok {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin: 0 12px;
  display: inline-block;
}

.navigation {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.nav-box {
  width: 240px;
}

.nav-item {
  width: 100%;
  height: 30px;
  color: #303133;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border-bottom: 1px solid #ebeef5;
  cursor: pointer;
  line-height: 30px;
  transition: border-bottom-color 0.3s;
  outline: 0;
  display: inline-block;
}

.nav-box >>> .el-collapse-item__header {
  width: 100%;
  height: 30px;
  color: #303133;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
}

.nav-box >>> .el-collapse-item__content {
  padding-bottom: 8px;
}

.nav-item-a {
  color: #303133;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
  text-decoration: none;
}

.nav-active {
  color: #004bc7;
}

.nav-subItem {
  color: #303133;
  font-size: 14px;
  font-weight: 500;
  padding-left: 40px;
  text-decoration: none;
}
</style>
