import enLocale from "element-ui/lib/locale/lang/en";
const en = {
  message: {
    hello: "Welcome to the official website of Wuhan Expo Center",
    logo: "Wuhan International Expo Center",
    topNav1: "Homepage",
    topNav2: "About us",
    subNav2_1: "Introduced",
    subNav2_2: "Meeting center",
    subNav2_3: "Hotels",
    subNav2_4: "Holiday Inn",
    subNav2_5: "Hall",
    topNav3: "Servse",
    subNav3_1: "Exhibition/Event Undertaking",
    subNav3_2: "Joint Exhibition Service",
    subNav3_3: "Advertising",
    subNav3_4: "More service",
    topNav4: "Schedule",
    topNav5: "News",
    topNav6: "Traffic",
    subNav6_1: "Location map",
    subNav6_2: "Traffic map",
    subNav6_3: "Parking map",
    topNav7: "Local",
    subNav7_1: "Hotel",
    subNav7_2: "Food",
    subNav7_3: "Scenic spot",
    topNav8: "Contact us",
    topNav9: "Download",
    time: "Time",
    notice: "Exhibition notice",
    customerService: "contact number：",
    eat: "Number of meals that can be accommodated",
    salesHotline: "Sales Hotline",
    cooperation: "business cooperation",
    star: "Star ranking",
    distance: "Distance sorting",
    price: "Price ranking",
    distanceCenter: "The distance from Wuhan International Expo Center is",
    scheduleDetail: "Detail",
    scheduleTime: "Time",
    scheduleAddress: "Address",
    exhibitionDetails: "Exhibition details",
    panoramic: "panoramic vr",
    promoVideo: "promo video",
    listOfExhibition: "List of Exhibition",
    byYear: "By year",
    byMonth: "By month",
    month: "month",
    zgTitle1: "Inside the login hall",
    zgTitle2: "Inside the exhibition hall",
    cases: "Cases undertaken",
    zlbProject: "Joint exhibition brand project",
    downloadTitle1: "Download Center",
    downloadTitle2: "Instructions for exhibition arrangement",
    downloadTitle3: "Exhibition hall plan",
    downloadTitle4: "Other files",
    download: "download",
  },
  ...enLocale,
};

export default en;
