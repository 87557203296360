<template>
  <div class="content">
    <div class="footer">
      <!-- 单一内容开始 -->
      <div class="footer-item1 flex">
        <div class="left-img">
          <img src="../assets/img/logo-img.png" alt="" />
        </div>
        <div class="right-content">
          <div class="footer-item1-msg1">武汉国际博览中心</div>
          <div class="footer-item1-msg2">Wuhan International Expo Center</div>
          <div class="footer-item1-msg3">
            地址：中国湖北省武汉市汉阳区鹦鹉大道619号
          </div>
          <div class="footer-item1-msg4">
            Address：619 parrot avenue, hanyang district, Wuhan city
          </div>
        </div>
      </div>
      <!-- 单一内容结束 -->
      <!-- 单一内容开始 -->
      <div class="footer-item2 flex">
        <div class="left-img">
          <img src="../assets/svg/footer-phone-icon.svg" alt="" />
        </div>
        <div class="right-content">
          <div class="footer-item2-msg1"><span>服务热线</span>/ Hotline</div>
          <div class="footer-item2-msg2">+86(0)27 86655088</div>
        </div>
      </div>
      <!-- 单一内容结束 -->
      <!-- 单一内容开始 -->
      <div class="footer-item2 flex">
        <div class="left-img">
          <img src="../assets/svg/footer-phone-icon.svg" alt="" />
        </div>
        <div class="right-content">
          <div class="footer-item2-msg1">
            <span>销售热线</span>/ Sales Hotline
          </div>
          <div class="footer-item2-msg2">+86(0)27 84696948</div>
        </div>
      </div>
      <!-- 单一内容结束 -->
      <!-- 单一内容开始 -->
      <div class="footer-item3">
        <div class="right-content">
          <div class="footer-item3-msg1">
            © 2021 武汉新城国际博览中心经营管理有限公司 版权所有
          </div>
          <div class="footer-item3-msg2 flex">
            <div class="footer-btn" @click="herfPulice">
              <img src="../assets/img/public.png" alt="" />
              鄂公网安备 42010502000530号
            </div>
            <div class="footer-btn" @click="herfICP">
              工信部备：鄂ICP备16013035号-1
            </div>
          </div>
        </div>
      </div>
      <!-- 单一内容结束 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 公网安备 跳转
    herfPulice() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010502000530"
      );
    },
    // 工信部备 跳转
    herfICP() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>

<style scoped>
.footer {
  height: 163px;
  background-color: #242a31;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.left-img {
  margin-right: 20px;
}

.right-content {
  height: 94px;
}

.footer-item1-msg1 {
  color: #ffffff;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
}

.footer-item1-msg2 {
  color: #a7aaad;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: 0 0 20px;
}

.footer-item1-msg3 {
  color: #a7aaad;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
}

.footer-item1-msg4 {
  color: #a7aaad;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: 0 0 20px;
}

.footer-item2-msg1 {
  color: #a7aaad;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}

.footer-item2-msg1 span {
  font-size: 16px;
}

.footer-item2-msg2 {
  color: #ffffff;
  font-size: 18px;
  font-family: Arial;
  font-weight: bold;
  line-height: 39px;
}

.footer-item3 {
  color: #a7aaad;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
}

.footer-item3-msg1 {
  margin-bottom: 4px;
}

.footer-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.footer-btn img {
  margin-right: 2px;
}
</style>
