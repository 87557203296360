<template>
  <div id="app">
    <!-- 语言切换开始 -->
    <language-change class="hidden-xs-only"></language-change>
    <!-- 语言切换结束 -->
    <router-view v-if="isRouterAlive" />
    <!-- 底部导航开始 -->
    <my-footer class="hidden-xs-only"></my-footer>
    <my-footer2 class="hidden-sm-and-up"></my-footer2>
    <!-- 底部导航结束 -->
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
      //禁止f12
      document.οnkeydοwn = new Function("event.returnValue=false");
    });
    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    document.onkeydown = function (e) {
      if (e && e.keyCode === 123) {
        e.returnValue = false;
        // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
        return false;
      }
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style></style>
