<template>
  <div class="content">
    <!-- 顶部内容开始 -->
    <div class="top">
      <!-- 内容容器开始 -->
      <div class="container">
        <div class="top-content">
          <!-- 左侧内容开始 -->
          <div class="top-content-left">
            {{ $t("message.hello") }}
          </div>
          <!-- 左侧内容结束 -->
          <!-- 右侧内容开始 -->
          <div class="top-content-right">
            <!-- 下拉选择语言开始 -->
            <el-dropdown trigger="click" @command="handleLanguage">
              <span class="el-dropdown-link language">
                <span>{{ languageMsg }}</span>
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cn">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 下拉选择语言结束 -->
            <!-- ufi开始 -->
            <div class="ufi" @click="herfUfi">
              <img src="../assets/img/ufi.png" />
            </div>
            <!-- ufi结束 -->
            <!-- 小程序hover开始 -->
            <el-dropdown trigger="hover">
              <span class="el-dropdown-link applet">
                <img src="../assets/svg/applet-icon.svg" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="code-img">
                  <img src="../assets/img/Applet-code.jpg" alt="" />
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 小程序结束 -->
            <!-- 抖音开始 -->
            <el-dropdown trigger="hover">
              <span class="el-dropdown-link Tiktok">
                <img src="../assets/svg/tiktok-icon.svg" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="code-img">
                  <img src="../assets/img/Tiktok-code.jpg" alt="" />
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- 抖音结束 -->
          </div>
          <!-- 右侧内容结束 -->
        </div>
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      languageMsg: "中文",
    };
  },
  mounted() {
    let value = localStorage.getItem("language");
    if (this.language != value) {
      if (value) {
        this.$store.commit("changeLanguage", value);
        this.handleLanguage(value);
      } else {
        this.handleLanguage("cn");
      }
    }
  },
  methods: {
    // 选择语言
    handleLanguage(command) {
      if (command == "cn") {
        this.languageMsg = "中文";
      } else if (command == "en") {
        this.languageMsg = "English";
      }
      this.$store.commit("changeLanguage", command);
      localStorage.setItem("language", command);
      this.$i18n.locale = command;
    },
    // ufi 跳转
    herfUfi() {
      window.open("https://www.ufi.org/");
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.top {
  background: rgba(245, 245, 245, 0.39);
}
.top-content {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-content-left {
  color: #666666;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
}

.top-content-right {
  display: flex;
  align-items: center;
}

.language {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.language span {
  margin-right: 16px;
}

.el-dropdown-menu__item {
  color: #000000;
}

.ufi {
  width: 59px;
  height: 24px;
  cursor: pointer;
  margin-left: 40px;
  display: inline-block;
}

.applet {
  width: 23px;
  height: 23px;
  cursor: pointer;
  margin: 0 40px;
  display: inline-block;
}

.code-img {
  width: 160px;
  cursor: pointer;
  padding: 10px;
  display: flex;
}

.el-dropdown-link img,
.code-img img {
  width: 100%;
}

.Tiktok {
  width: 19px;
  height: 23px;
  cursor: pointer;
  display: inline-block;
}
</style>
