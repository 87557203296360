<template>
  <div class="container">
    <!-- logo开始 -->
    <div class="header-top">
      <div class="header-top-left">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <div class="header-top-right" v-show="phoneShow == 1">
        <div class="phone-img">
          <img src="../assets/svg/header-phone-icon.svg" alt="" />
        </div>
        <div class="phone-content">
          <div class="label">{{ $t("message.customerService") }}</div>
          <div class="msg">027-86655088</div>
        </div>
      </div>
    </div>
    <!-- logo结束 -->
    <!-- 导航开始 -->
    <div class="header-nav" @mouseleave="getNavActive">
      <router-link
        class="nav-item"
        :class="navActive == 0 ? 'nav-active' : ''"
        @mouseenter.native="navActive = 0"
        to="/"
        >{{ $t("message.topNav1") }}</router-link
      >
      <el-dropdown trigger="hover">
        <router-link
          class="el-dropdown-link nav-item"
          :class="navActive == 1 ? 'nav-active' : ''"
          @mouseenter.native="navActive = 1"
          to="/about?tab=0"
        >
          {{ $t("message.topNav2") }}
        </router-link>
        <el-dropdown-menu slot="dropdown" class="newDrop">
          <div class="nav-subItem" @click="handleSubNav('/about?tab=0')">
            {{ $t("message.subNav2_1") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/about?tab=1')">
            {{ $t("message.subNav2_2") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/about?tab=2')">
            {{ $t("message.subNav2_3") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/about?tab=3')">
            {{ $t("message.subNav2_4") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/about?tab=4')">
            {{ $t("message.subNav2_5") }}
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="hover">
        <router-link
          class="el-dropdown-link nav-item"
          :class="navActive == 2 ? 'nav-active' : ''"
          @mouseenter.native="navActive = 2"
          to="/service?tab=1"
        >
          {{ $t("message.topNav3") }}
        </router-link>
        <el-dropdown-menu slot="dropdown" class="newDrop">
          <div class="nav-subItem" @click="handleSubNav('/service?tab=1')">
            {{ $t("message.subNav3_1") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/service?tab=2')">
            {{ $t("message.subNav3_2") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/service?tab=3')">
            {{ $t("message.subNav3_3") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/service?tab=4')">
            {{ $t("message.subNav3_4") }}
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link
        class="nav-item"
        :class="navActive == 3 ? 'nav-active' : ''"
        @mouseenter.native="navActive = 3"
        to="/schedule"
        >{{ $t("message.topNav4") }}</router-link
      >
      <router-link
        class="nav-item"
        :class="navActive == 4 ? 'nav-active' : ''"
        @mouseenter.native="navActive = 4"
        to="/news"
        >{{ $t("message.topNav5") }}</router-link
      >
      <el-dropdown trigger="hover">
        <router-link
          class="el-dropdown-link nav-item"
          :class="navActive == 5 ? 'nav-active' : ''"
          @mouseenter.native="navActive = 5"
          to="/traffic?tab=0"
        >
          {{ $t("message.topNav6") }}
        </router-link>
        <el-dropdown-menu slot="dropdown" class="newDrop">
          <div class="nav-subItem" @click="handleSubNav('/traffic?tab=0')">
            {{ $t("message.subNav6_1") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/traffic?tab=1')">
            {{ $t("message.subNav6_2") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/traffic?tab=2')">
            {{ $t("message.subNav6_3") }}
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="hover">
        <router-link
          class="el-dropdown-link nav-item"
          :class="navActive == 6 ? 'nav-active' : ''"
          @mouseenter.native="navActive = 6"
          to="/Local?tab=0"
        >
          {{ $t("message.topNav7") }}
        </router-link>
        <el-dropdown-menu slot="dropdown" class="newDrop">
          <div class="nav-subItem" @click="handleSubNav('/Local?tab=0')">
            {{ $t("message.subNav7_1") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/Local?tab=1')">
            {{ $t("message.subNav7_2") }}
          </div>
          <div class="nav-subItem" @click="handleSubNav('/Local?tab=2')">
            {{ $t("message.subNav7_3") }}
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link
        class="nav-item"
        :class="navActive == 7 ? 'nav-active' : ''"
        @mouseenter.native="navActive = 7"
        to="/contentUs"
        >{{ $t("message.topNav8") }}</router-link
      >
      <router-link
        class="nav-item"
        :class="navActive == 8 ? 'nav-active' : ''"
        @mouseenter.native="navActive = 8"
        to="/download"
        >{{ $t("message.topNav9") }}</router-link
      >
    </div>
    <!-- 导航结束 -->
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      navActive: "",
    };
  },
  props: {
    active: String,
    phoneShow: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getNavActive();
  },
  methods: {
    refresh() {
      this.reload();
    },
    // 选择子导航
    handleSubNav(value) {
      this.$router.push({ path: value });
      this.refresh();
    },
    // 获取导航默认选中
    getNavActive() {
      this.navActive = this.active;
    },
  },
};
</script>

<style scoped>
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top-right {
  display: flex;
}

.phone-img {
  margin-right: 8px;
}

.phone-content .label {
  color: #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
}

.phone-content .msg {
  color: #ffffff;
  font-size: 26px;
  font-family: Arial;
  font-weight: bold;
  line-height: 31px;
}

.header-nav {
  background: #004bc7;
  opacity: 0.9;
  margin-top: 20px;
  display: flex;
}

.nav-item {
  width: 150px;
  height: 70px;
  color: #ffffff;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 70px;
  display: inline-block;
}

.nav-item:hover {
  background: #1691fa;
}

.nav-active {
  background: #1691fa;
}

.nav-subItem {
  width: 214px;
  height: 44px;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 44px;
  cursor: pointer;
}

.nav-subItem:hover {
  color: #ffffff;
  background-color: #1691fa;
}

.newDrop {
  background-color: #004bc7;
  border: 1px solid #004bc7;
}

.newDrop[x-placement^="left"] .popper__arrow::after {
  border-left-color: #004bc7;
}

.newDrop[x-placement^="right"] .popper__arrow::after {
  border-right-color: #004bc7;
}

.newDrop[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #004bc7;
  top: 0;
}

.newDrop[x-placement^="top"] .popper__arrow::after {
  border-top-color: #004bc7;
}

.newDrop >>> .nav-subItem {
  width: 270px;
}
</style>
