import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: "cn",
  },
  mutations: {
    changeLanguage(state, value) {
      this.state.language = value;
    },
  },
  actions: {},
  modules: {},
});
