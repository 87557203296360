import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("../views/Schedule.vue"),
  },
  {
    path: "/scheduleDetail",
    name: "ScheduleDetail",
    component: () => import("../views/ScheduleDetail.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/newsDetail",
    name: "NewsDetail",
    component: () => import("../views/NewsDetail.vue"),
  },
  {
    path: "/Local",
    name: "Local",
    component: () => import("../views/Local.vue"),
  },
  {
    path: "/traffic",
    name: "Traffic",
    component: () => import("../views/Traffic.vue"),
  },
  {
    path: "/contentUs",
    name: "ContentUs",
    component: () => import("../views/ContentUs.vue"),
  },
  {
    path: "/download",
    name: "Download",
    component: () => import("../views/Download.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
  },
  { path: "*", redirect: "/" },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
