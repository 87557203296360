import zhLocale from "element-ui/lib/locale/lang/zh-CN";
const cn = {
  message: {
    hello: "欢迎访问武汉国际博览中心官方网站",
    logo: "武汉国际博览中心",
    topNav1: "首页",
    topNav2: "关于国博",
    subNav2_1: "展馆",
    subNav2_2: "会议中心",
    subNav2_3: "洲际酒店",
    subNav2_4: "套房假日酒店",
    subNav2_5: "汉厅",
    topNav3: "展会服务",
    subNav3_1: "展会/活动承接",
    subNav3_2: "展会自联办服务",
    subNav3_3: "广告服务",
    subNav3_4: "其他服务",
    topNav4: "展会排期",
    topNav5: "新闻动态",
    topNav6: "交通导视",
    subNav6_1: "区位图",
    subNav6_2: "公交地铁线路图",
    subNav6_3: "馆内停车场示意图",
    topNav7: "本地生活",
    subNav7_1: "酒店",
    subNav7_2: "餐饮",
    subNav7_3: "景区",
    topNav8: "联系我们",
    topNav9: "下载中心",
    time: "时间",
    notice: "展会预告",
    customerService: "联系电话：",
    eat: "可容纳餐位数",
    salesHotline: "销售热线",
    cooperation: "业务合作",
    star: "星级排序",
    distance: "距离排序",
    price: "价格排序",
    distanceCenter: "距离  武汉国际博览中心",
    scheduleDetail: "详情页",
    scheduleTime: "展会时间",
    scheduleAddress: "展会地点",
    exhibitionDetails: "展会详情",
    panoramic: "国博全景 VR",
    promoVideo: "宣传片",
    listOfExhibition: "展会排期",
    byYear: "按年",
    byMonth: "按月",
    month: "月",
    zgTitle1: "登录厅内部",
    zgTitle2: "展厅内部",
    cases: "承办案例",
    zlbProject: "自联办展品牌项目",
    downloadTitle1: "下载中心",
    downloadTitle2: "布展须知",
    downloadTitle3: "展馆平面图",
    downloadTitle4: "其他文件",
    download: "请点击下载",
  },
  ...zhLocale,
};

export default cn;
