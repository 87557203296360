import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// 引入axios
Vue.prototype.$axios = axios;
// axios.defaults.baseURL = "http://192.168.31.54:8084/cms/";
axios.defaults.baseURL = "http://47.122.3.90/cms/";
// 引入公共样式
import "./assets/css/common.css";

// 引用 element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
Vue.use(ElementUI);

// 视频播放
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
Vue.use(VideoPlayer);

import i18n from "./i18n/i18n";

Vue.config.productionTip = false;

// 引用全局组件
import LanguageChange from "./components/LanguageChange.vue";
Vue.component("language-change", LanguageChange);

import LanguageChange2 from "./components/LanguageChange2.vue";
Vue.component("language-change2", LanguageChange2);

import MyHeader from "./components/MyHeader.vue";
Vue.component("my-header", MyHeader);

import MyFooter from "./components/MyFooter.vue";
Vue.component("my-footer", MyFooter);

import MyFooter2 from "./components/MyFooter2.vue";
Vue.component("my-footer2", MyFooter2);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
